import React, { useState, useEffect, useRef, useMemo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import disableScroll from "disable-scroll"
import { useIsInViewport } from "../features/IsInViewport"
import message from "../../assets/img/message.svg"
import claim from "../../assets/img/claim.svg"
import diamond from "../../assets/img/diamond.svg"
import circleX from "../../assets/img/x-circle.svg"
import notebook from "../../assets/img/notebook.svg"
import chat from "../../assets/img/message-chat-square.svg"
import fileCheck from "../../assets/img/file-check.svg"
import fileHeart from "../../assets/img/file-heart.svg"
import fileX from "../../assets/img/file-x.svg"
import ABanner from "../features/ab.component"
import letterImg from "/src/assets/img/from_anywhere_1.png"
import letterImg1 from "/src/assets/img/thousands_times.png"
import letterImg2 from "/src/assets/img/from_anywhere.png"
import letterImg3 from "/src/assets/img/print_services.png"
import option1 from "./../../assets/img/intro-bg-mobile.svg"
import option2 from "./../../assets/img/uspsTrackingIntegration.png"
import option3 from "./../../assets/img/dynamicTrackingPortal.png"
import option4 from "./../../assets/img/flexibleMailingOptions.png"
import { useLocation } from "@reach/router"
import envelope from "/src/assets/img/envelopSliderIcon.svg"
import letter from "/src/assets/img/letterSliderIcon.svg"
import monitor from "/src/assets/img/monitorSliderIcon.svg"
import reward from "/src/assets/img/rewardSliderIcon.svg"

const IntroIndustryComponent = props => {
  const location = useLocation()
  const [selectedId, setSelectedId] = useState(1)
  const [activeSlideContent, setActiveSlideContent] = useState(false)
  const [visible, setVisible] = useState(false)
  const isMouseEnter = useRef(false)
  const ref1 = useRef(null)
  const isUp = useRef(false)
  const isInViewport = useIsInViewport(ref1)
  const title = props.title
  const text = props.text
  const features = props.features
  const texts = props.texts
  const ref = useRef()
  var debounce = true

  const t = (key, vocabulary) => {
    return vocabulary[key] || `{{${key}}}`
  }
  const slideList = features.map(e => {
    return {
      id: e.id,
      Img: e.img,
      image: e.smallImg,
      title: t(e.title, texts),
      subTitle: t(e.text, texts),
    }
  })
  let delta
  const counter2 = useRef(selectedId)

  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => {
        setVisible(true)
      }, [300])
    }
  })

  const handleScroll = e => {
    delta = e.deltaY
    if (isMouseEnter.current) {
      if (window.innerWidth > 1200) {
        if (counter2.current <= 1 && e.deltaY < 0) {
          disableScroll.off()
        } else if (counter2.current >= 3 && e.deltaY > 0) {
          disableScroll.off()
        } else {
          if (debounce) {
            debounce = false
            if (
              window.scrollY >= ref?.current?.offsetTop - 60 &&
              window.scrollY <= ref?.current?.offsetTop
            ) {
              if (e.deltaY > 0) {
                isUp.current = true
                setActiveSlideContent(true)
                setTimeout(() => {
                  setSelectedId(prevState => {
                    ++counter2.current
                    return prevState + 1
                  })
                  setActiveSlideContent(false)
                }, 500)
              } else {
                isUp.current = false
                setActiveSlideContent(true)
                setTimeout(() => {
                  setSelectedId(prevState => {
                    --counter2.current
                    return prevState - 1
                  })
                  setActiveSlideContent(false)
                }, 500)
              }
            }
            setTimeout(() => {
              debounce = true
            }, [750])
          }
        }
      } else {
        disableScroll.off()
      }
    } else {
      disableScroll.off()
    }
  }
  const handleSetSelectedId = id => {
    if (counter2.current > id) {
      isUp.current = false
    } else {
      isUp.current = true
    }
    setActiveSlideContent(true)
    counter2.current = id
    setTimeout(() => {
      setSelectedId(id)
      setActiveSlideContent(false)
    }, 500)
  }
  const handleMouseEnter = () => {
    isMouseEnter.current = true
  }
  const handleMouseLeave = () => {
    isMouseEnter.current = false
  }
  const scrollHandler = e => {
    if (delta) {
      if (isMouseEnter.current) {
        if (window.innerWidth > 1200) {
          if (
            window.scrollY >= ref?.current?.offsetTop - 60 &&
            window.scrollY <= ref.current.offsetTop
          ) {
            if (counter2.current <= 1 && delta < 0) {
              disableScroll.off()
            } else if (counter2.current >= 3 && delta > 0) {
              disableScroll.off()
            } else {
              disableScroll.on()
            }
          }
        } else {
          disableScroll.off()
        }
      } else {
        disableScroll.off()
      }
    }
  }

  useEffect(() => {
    window.addEventListener("wheel", handleScroll)
    return () => {
      window.removeEventListener("wheel", handleScroll)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler)
    return () => window.removeEventListener("scroll", scrollHandler)
  }, [])

  const typesData = useMemo(() => {
    const path = location.pathname
    if (["/law-firms/"].includes(path)) {
      return [
        {
          img: message,
          text: (
            <>
              Demand
              <br />
              Letters
            </>
          ),
        },
        {
          img: claim,
          text: (
            <>
              Notice of
              <br /> Intent to Sue
            </>
          ),
        },
        {
          img: diamond,
          text: (
            <>
              Cease and Desist <br /> Letters{" "}
            </>
          ),
        },
        {
          img: circleX,
          text: (
            <>
              Summons and <br /> Complaints
            </>
          ),
        },
        {
          img: notebook,
          text: (
            <>
              Notice to <br /> Appear
            </>
          ),
        },
        { img: chat, text: <>Subpoenas</> },
        {
          img: fileCheck,
          text: (
            <>
              Eviction <br /> Notices
            </>
          ),
        },
        {
          img: fileHeart,
          text: (
            <>
              Legal Notices <br /> for Publication
            </>
          ),
        },
        {
          img: fileX,
          text: (
            <>
              Notice of <br /> Deposition
            </>
          ),
        },
        {
          img: message,
          text: (
            <>
              Notice of
              <br />
              Appeal
            </>
          ),
        },
        {
          img: claim,
          text: (
            <>
              Notice of
              <br /> Hearing
            </>
          ),
        },
        {
          img: diamond,
          text: (
            <>
              Lien <br /> Notices{" "}
            </>
          ),
        },
        {
          img: circleX,
          text: (
            <>
              Settlement <br /> Offers
            </>
          ),
        },
      ]
    } else if (["/insurance/", "/financial-institutions/"].includes(path)) {
      return [
        {
          img: message,
          text: (
            <>
              Policy Renewal
              <br />
              Notices
            </>
          ),
        },
        {
          img: claim,
          text: (
            <>
              Claim <br /> Acknowledgment <br /> Letters
            </>
          ),
        },
        {
          img: diamond,
          text: (
            <>
              Premium Due <br /> Notices{" "}
            </>
          ),
        },
        {
          img: circleX,
          text: (
            <>
              Policy Cancellation <br /> Notices
            </>
          ),
        },
        {
          img: notebook,
          text: (
            <>
              Coverage <br /> Determination <br /> Letters
            </>
          ),
        },
        {
          img: chat,
          text: (
            <>
              Appeal Response <br /> Letters
            </>
          ),
        },
        {
          img: fileCheck,
          text: (
            <>
              Underwriting <br /> Letters
            </>
          ),
        },
        {
          img: fileHeart,
          text: (
            <>
              Endorsement <br /> Notices
            </>
          ),
        },
        {
          img: fileX,
          text: (
            <>
              Claim Denial <br /> Letters
            </>
          ),
        },
      ]
    } else if (["/construction/", "/self-storage/"].includes(path)) {
      return [
        {
          img: message,
          text: (
            <>
              Rent Due
              <br />
              Notices
            </>
          ),
        },
        {
          img: claim,
          text: (
            <>
              Lease <br /> Agreements
            </>
          ),
        },
        {
          img: diamond,
          text: (
            <>
              Maintenances <br /> Notices{" "}
            </>
          ),
        },
        {
          img: circleX,
          text: (
            <>
              Eviction <br /> Notices
            </>
          ),
        },
        {
          img: notebook,
          text: (
            <>
              Lease Renewal <br /> Notices
            </>
          ),
        },
        {
          img: chat,
          text: (
            <>
              Property Inspection <br /> Notices
            </>
          ),
        },
        {
          img: fileCheck,
          text: (
            <>
              Late Payment <br /> Notices
            </>
          ),
        },
        {
          img: fileHeart,
          text: (
            <>
              Violation <br /> Notices
            </>
          ),
        },
        {
          img: fileX,
          text: (
            <>
              Tenant <br /> Communication
            </>
          ),
        },
      ]
    } else if (["/small-businesses/"].includes(path)) {
      return [
        {
          img: message,
          text: (
            <>
              Assessment
              <br />
              Notices
            </>
          ),
        },
        {
          img: claim,
          text: (
            <>
              Meeting <br /> Notices
            </>
          ),
        },
        {
          img: diamond,
          text: (
            <>
              Violation <br /> Notices{" "}
            </>
          ),
        },
        {
          img: circleX,
          text: (
            <>
              Annual Meeting <br /> Notices
            </>
          ),
        },
        {
          img: notebook,
          text: (
            <>
              Budget <br /> Notices
            </>
          ),
        },
        {
          img: chat,
          text: (
            <>
              Maintenances
              <br /> Notices
            </>
          ),
        },
        {
          img: fileCheck,
          text: (
            <>
              Dues Collection <br /> Letters
            </>
          ),
        },
        {
          img: fileHeart,
          text: (
            <>
              Community <br /> Updates
            </>
          ),
        },
        {
          img: fileX,
          text: (
            <>
              Intent to Lien <br /> Notices
            </>
          ),
        },
      ]
    } else {
      return [
        {
          img: message,
          text: (
            <>
              Public
              <br />
              Notices
            </>
          ),
        },
        {
          img: claim,
          text: (
            <>
              Regulatory <br /> Notices
            </>
          ),
        },
        {
          img: diamond,
          text: (
            <>
              Tax <br /> Bills{" "}
            </>
          ),
        },
        {
          img: circleX,
          text: (
            <>
              Permit <br /> Approvals
            </>
          ),
        },
        {
          img: notebook,
          text: (
            <>
              Licensing <br /> Notices
            </>
          ),
        },
        {
          img: chat,
          text: (
            <>
              Public Hearing
              <br /> Notices
            </>
          ),
        },
        {
          img: fileCheck,
          text: (
            <>
              Ordinance <br /> Notices
            </>
          ),
        },
        {
          img: fileHeart,
          text: (
            <>
              Legal Notices <br /> for Publication
            </>
          ),
        },
        {
          img: fileX,
          text: (
            <>
              Delinquency Tax <br /> Notices
            </>
          ),
        },
      ]
    }
  }, [location.pathname])

  const solutionsData = useMemo(() => {
    const baseSolutions = [
      {
        descr: (
          <>
            Manage all your mailing needs through our user-friendly online
            platform.
          </>
        ),
        text: <>Online Mailing Services</>,
      },
      {
        descr: (
          <>
            Track your letters and notices with detailed tracking history,
            letter images, and electronic signatures—all in one place.
          </>
        ),
        text: <>Dynamic Tracking Portal</>,
      },
      {
        descr: (
          <>
            A one-stop shop for all your letters, notices, checks, and documents
            required to be mailed through the USPS.
          </>
        ),
        text: <>Full-Service Print and Mailing</>,
      },
      {
        descr: (
          <>
            Fast and dependable. Your critical letters and notices are mailed on
            the following business day.
          </>
        ),
        text: <>Next Day Mailing</>,
      },
      {
        descr: (
          <>
            Easily send large volumes of mail in seconds using our “Import from
            File” or “Extract from PDF” features.
          </>
        ),
        text: <>Bulk Mailing</>,
      },
      {
        descr: (
          <>
            Standard return envelopes, custom pre-addressed stamped envelopes,
            and static inserts.
          </>
        ),
        text: <>Inserts</>,
      },
      {
        descr: (
          <>Seamlessly integrate our services into your existing systems.</>
        ),
        text: <>Print and Mail API</>,
      },
      {
        descr: <>Ensure legal proof of mailing for important documents.</>,
        text: <>Affidavits of Mailing</>,
      },
    ]
    if (location.pathname === "/insurance/") {
      return [
        ...baseSolutions,
        {
          descr: (
            <>
              A secure, professional, and cost-effective solution for sending
              single or bulk checks.
            </>
          ),
          text: <>Check Printing and Mailing Services</>,
        },
      ]
    }

    return baseSolutions
  }, [location.pathname])

  const blocks = [
    // {
    //   id: 1,
    //   title: "Envelope Options",
    //   description: `<div style="display: flex; gap: 10px;">
    //     <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">Certified Mail #10 <br /> Envelopes <br /> </span> <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw;">Accommodates up to 5 physical pages, including the cover sheet.</span></div>
    //     <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">Certified Mail 9 x 12 Flat Envelopes</span>    <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw">Can hold up to 50 physical pages, including the cover sheet</span></div>
    //   </div>`,
    //   imgSrc: option1,
    //   icon: envelope,
    // },
    {
      id: 1,
      title: "Expertise",
      description: `<div style="padding: 5px 5px; display: inline-block;">We are specialists in mailing services for insurance companies, handling complex documents with ease.</div>`,
      imgSrc: option1,
      icon: reward,
    },
    {
      id: 2,
      title: "Security",
      description: `<div style="padding: 5px 5px; display: inline-block;">Our platform is safe and protected, ensuring your data is secure.</div>`,
      imgSrc: option1,
      icon: monitor,
    },
    {
      id: 3,
      title: "Simplicity",
      description: `<div style="padding: 5px 5px; display: inline-block;">Our application is easy to use, making your mailing processes hassle-free.</div>`,
      imgSrc: option1,
      icon: letter,
    },
  ]

  const subtitleText = useMemo(() => {
    const path = location.pathname
    const subtitles = {
      "/law-firms/":
        "We are equipped to manage a wide range of legal notices and letters, including:",
      "/small-businesses/":
        "We handle a variety of HOA-related notices and letters, including:",
      "/financial-institutions/":
        "We handle a variety of insurance-related notices and letters, including:",
      "/insurance/":
        "We handle a variety of insurance-related notices and letters, including:",
      "/construction/":
        "We handle a variety of property management-related notices and letters, including:",
      "/self-storage/":
        "We handle a variety of property management-related notices and letters, including:",
      "/state-and-local-government/":
        "We handle a variety of government-related notices and letters, including:",
    }
    return (
      subtitles[path] ||
      "We handle a variety of notices and letters, including:"
    )
  }, [location.pathname])

  const [expandedBlock, setExpandedBlock] = useState(1)
  const [activeImage, setActiveImage] = useState(option1)
  const [isFading, setIsFading] = useState(false)

  const toggleBlock = blockId => {
    if (expandedBlock !== blockId) {
      setIsFading(true)
      setTimeout(() => {
        setExpandedBlock(blockId)
        setActiveImage(blocks.find(block => block.id === blockId).imgSrc)
        setIsFading(false)
      }, 500)
    }
  }

  return (
    <div className="introIndustrySlide">
      <div className="slideContent">
        <div className="mainContainer gutter60">
          <div className="introIndustrySlideTitleWrapper">
            <h1 className="introIndustrySlideTitle">{title}</h1>
            <div className="introIndustrySlideSubTitleWrapper">
              <p className="introIndustrySlideSubTitle mb40">{text}</p>
              <div className="btnHolder">
                <a
                  href="https://app.uploadletters.com/register"
                  className="button introIndustrySlideButton"
                >
                  Get Started <div className="whiteDot" />
                </a>
              </div>
            </div>
          </div>

          <div className="typesSlide">
            <div className="textCenter typesTitle">
              Types of Notices and Letters We Handle
            </div>
            <div className="typesSubTitle">{subtitleText}</div>
          </div>

          <div className="iconsContainer">
            {typesData.map((item, index) => (
              <div key={index} className="iconBlock">
                <img src={item.img} alt={item.text} className="iconImage" />
                <p className="iconText">{item.text}</p>
              </div>
            ))}
          </div>

          {/* <FeaturesListComponent features={features} texts={texts}/> */}
        </div>
        <div className="introSlideHowToGet">
          <div className="slideContent">
            <div className="mainContainer gutter120">
              <div className="textPrimary titlePrice mb30">
                USPS Service Types
              </div>
              <p className="uspsServiceBlockDesription">
                We offer a variety of USPS services to meet your specific needs:
              </p>
              <div className="uspsServiceBlock">
                <div className="uspsServiceBlockTitle">First-Class Mail</div>
                <div className="uspsServiceBlockDescr">
                  Cost-effective and reliable mailing option for standard
                  letters.
                </div>
              </div>
              <div className="uspsServiceBlock">
                <div className="uspsServiceBlockTitle">Certified Mail</div>
                <div className="uspsServiceBlockDescr">
                  Track and confirm delivery of your important notices.
                </div>
              </div>
              <div className="uspsServiceBlock">
                <div className="uspsServiceBlockTitle">Priority Mail</div>
                <div className="uspsServiceBlockDescr">
                  Fast and efficient delivery for urgent documents.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="typesSlide">
          <div className="textCenter typesTitle">
            Comprehensive Mailing Solutions
          </div>
          <div className="typesSubTitle">
            Our extensive mail services ensure that your letters and notices are
            handled with care and accuracy. We offer:
          </div>
        </div>

        <div className="iconsContainer">
          {solutionsData.map((item, index) => (
            <div key={index} className="solutionsBlock">
              <p className="iconTextTitle">{item.text}</p>
              <p className="iconTextDescr">{item.descr}</p>
            </div>
          ))}
        </div>

        <div className="gutter60">
          <div className="mainSliderContainer paddingRight">
            <div className="splitContainer">
              <div className="optionsContainer">
                <h1 className="typesTitle textLeft">
                  Why choose uploadletters.com?
                </h1>
                {blocks.map(block => (
                  <div
                    key={block.id}
                    className={`optionBlock ${
                      expandedBlock === block.id ? "expanded" : ""
                    }`}
                    onClick={() => toggleBlock(block.id)}
                  >
                    <div className="optionIconBlock">
                      <img src={block.icon} alt="icon" className="optionIcon" />
                    </div>
                    <div>
                      <div className="optionTitleBlock">
                        <div className="optionTitle">{block.title}</div>
                      </div>
                      {expandedBlock === block.id && (
                        <p
                          className="optionContent"
                          dangerouslySetInnerHTML={{
                            __html: block.description,
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={`imageContainer ${
                  isFading ? "fade-out" : "fade-in"
                }`}
              >
                <img src={activeImage} alt="Selected Option" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bannerContainer">
        <ABanner />
      </div>
    </div>
  )
}

export default IntroIndustryComponent
