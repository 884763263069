import React from 'react';
import PriceComponent from '../components/price/price.component';
import IntroIndustryComponent from '../components/intro-industry/intro-industry.component';
import { MainLayout } from '../layouts/main.layout';
import Seo from "../components/seo";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet';

const IndustryItem = ({ pageContext: { id }, data, location, path }) => {
  const t = (key, vocabulary) => {
    return vocabulary[key] || `{{${key}}}`
  }

  const item = data.dataJson.industries[id - 1];
  const texts = data.allTexts.edges[0].node;

  // const titles = {
  //   1: 'UploadLetters.com - Law Firms',
  //   2: 'UploadLetters.com - Insurance',
  //   3: 'UploadLetters.com - Small Businesses',
  //   4: 'UploadLetters.com - Construction',
  //   5: 'UploadLetters.com - Financial Institutions',
  //   6: 'UploadLetters.com - State and Local Government',
  //   7: 'UploadLetters.com - Self Storage',
  //   8: 'UploadLetters.com - Engineering Firms',
  //   9: 'UploadLetters.com - Fortune 500 Companies',
  // };

  // const descriptions = {
  //   1: 'Elevate legal mailings with UploadLetters.com. Send letters securely online, ensuring proper handling of your critical legal documents.',
  //   2: 'Empower insurance communication with UploadLetters.com. Trusted for Certified Mail tracking and seamless online mailings. Rely on us for secure, efficient mail services.',
  //   3: 'Boost small business with UploadLetters.com. Certified Mail, Priority Mail and First-Class Mail tracking, Experience simplicity in mailing.',
  //   4: 'Elevate construction mailings with UploadLetters.com – trusted for Certified Mail, efficient online mailings. Count on us for comprehensive solutions.',
  //   5: 'Transform mailing processes with UploadLetters.com – your partner for Certified Mail and efficient online letter services. Choose us for critical mailings.',
  //   6: 'State and local government agencies trust UploadLetters.com for critical online mailings, including Certified Mail, Priority Mail, and First-Class Mail print and mail services.',
  //   7: 'Automate self-storage mail with UploadLetters.com – your partner for Certified Mail tracking and efficient print-to-mail solutions. Mail letters online.',
  //   8: 'Transform engineering correspondence with UploadLetters.com – your Certified Mail partner for complex documents, priority mail, and secure online mailing.',
  //   9: 'Send Certified Mail online effortlessly with our dedicated mail service. Mail letters online securely, ensuring reliable and certified delivery.',
  // };

  // const seo = {
  //   title: titles[id],
  //   description: descriptions[id],
  
  // };

  return (
    <>
    <MainLayout location={location}>
    <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
        </script>
      </Helmet>
      <div className="landingSlidesHolder">
        <IntroIndustryComponent
          title={t(item.title, texts)}
          text={t(item.text, texts)}
          features={item.features}
          texts={texts} />
        {/* <PriceComponent /> */}
      </div>
    </MainLayout>
    </>
  );
};

IndustryItem.defaultProps = {
  location: {},
};

export default IndustryItem;

export const Head = ({location, params, data, pageContext: { id, headerTitle, headerDescription }}) => (
    <Seo title={headerTitle} description={headerDescription} />
)

export const query = graphql`
  query {
    dataJson {
      industries {
        id
        title
        text
        features {
          id
          text
          title
          smallImg {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          img {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    allTexts {
      edges {
        node {
          industries_1_features_1_text
          industries_1_features_1_title
          industries_1_features_2_text
          industries_1_features_2_title
          industries_1_features_3_text
          industries_1_features_3_title
          industries_1_text
          industries_1_title
          industries_2_features_1_text
          industries_2_features_1_title
          industries_2_features_2_text
          industries_2_features_2_title
          industries_2_features_3_text
          industries_2_features_3_title
          industries_2_text
          industries_2_title
          industries_3_features_1_text
          industries_3_features_1_title
          industries_3_features_2_text
          industries_3_features_2_title
          industries_3_features_3_text
          industries_3_features_3_title
          industries_3_text
          industries_3_title
          industries_4_features_1_text
          industries_4_features_1_title
          industries_4_features_2_text
          industries_4_features_2_title
          industries_4_features_3_text
          industries_4_features_3_title
          industries_4_text
          industries_4_title
          industries_5_features_1_text
          industries_5_features_1_title
          industries_5_features_2_text
          industries_5_features_2_title
          industries_5_features_3_text
          industries_5_features_3_title
          industries_5_text
          industries_5_title
          industries_6_features_1_text
          industries_6_features_1_title
          industries_6_features_2_text
          industries_6_features_2_title
          industries_6_features_3_text
          industries_6_features_3_title
          industries_6_text
          industries_6_title
          industries_7_features_1_text
          industries_7_features_1_title
          industries_7_features_2_text
          industries_7_features_2_title
          industries_7_features_3_text
          industries_7_features_3_title
          industries_7_text
          industries_7_title
          
        }
      }
    }
  }
`
